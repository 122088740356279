/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 10, 2023 */



@font-face {
    font-family: 'akkuratregular';
    src: url('akkurat-webfont.woff2') format('woff2'),
         url('akkurat-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'akkurat-boldregular';
    src: url('akkurat-bold-webfont.woff2') format('woff2'),
         url('akkurat-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}