@import "Fonts/Akkurat/Akkurat.css";
@import "Fonts/Turnip/Turnip.css";


//  This CSS resource incorporates links to font software which is the
//    valuable copyrighted property of Type Network, Inc. and/or their
//    suppliers. You may not attempt to copy, install, redistribute,
//    convert, modify or reverse engineer this font software. Please
//    contact Type Network with any questions: www.typenetwork.com 

body {
	font-size: 18px;
	font-family: "Turnip RE", Georgia, "Times New Roman", Times, serif;
	line-height: 1.618;
	color: #222;
	background-color: #fff;
	margin: 0;
	overflow-x: hidden !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Salvo Serif Cond", Georgia, "Times New Roman", Times, serif;
	line-height: 1.1;
	text-rendering: optimizeLegibility;
}

html input[type="button"],
input[type="reset"],
input[type="submit"],
button,
.button {
	box-shadow: none;
	text-shadow: none;
}

html input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover,
button:hover,
.button:hover {
	box-shadow: none;
	text-shadow: none;
}

a,
span {
	box-shadow: none;
	text-shadow: none;
	background-image: none;
}

body * { 
    -webkit-font-smoothing: subpixel-antialiased !important; 
    text-rendering:optimizeLegibility !important;
	letter-spacing:inherit !important;
}

.gContainerMain {
	padding-left: 5rem !important;
	padding-right: 5rem !important;
}

.gContainerMain2 {
	padding-left: 5rem !important;
	padding-right: 5rem !important;
}

.gContainerMain3 {
	padding-left: 5rem !important;
	padding-right: 5rem !important;
}

section.mainContent {
	overflow: hidden;
	background: #fff !important;
}

body {
	background: #F8F9FA;
	color: #455A64;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 32px;
}

.nav-pills {
	// border-bottom: 1px solid #D7D7D7;
	overflow: hidden;
	flex-wrap: nowrap;
}

.nav-item {
	margin-right: 10px !important;
	min-width: fit-content;
}

span.mat-mdc-select-min-line.ng-tns-c7-0.ng-star-inserted {
	color: #101828;
	font-family: 'akkuratregular';
	font-style: normal;
	font-weight: 500;
	letter-spacing: -0.5px;
}

span.mat-mdc-select-min-line.ng-tns-c7-1.ng-star-inserted {
	font-family: 'akkuratregular';
}

.mat-mdc-radio-button .mdc-radio {
	padding: calc((var(--mdc-radio-state-layer-size, 20px) - 15px) / 2) !important;
	vertical-align: middle;
}

.svg-inline--fa {
	height: 2em;
}


// Loadin screen animation

.loader-div {
	position: fixed;
	top: 0;
	left: 0;
	background-color: #dcd6d612;
	height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
}

.loader {
	position: relative;
}

.one {
	position: absolute;
	border-radius: 50%;
	background: #FFFFFF;
	opacity: .0;
	animation: loading 1.3s .65s infinite;
	height: 20px;
	width: 20px;
}

.two {
	position: absolute;
	border-radius: 50%;
	background: #0D2D6C;
	opacity: .0;
	animation: loading 1.3s infinite;
	height: 20px;
	width: 20px;
}


@keyframes loading {
	0% {
		opacity: .0;
		transform: scale(.15);
		box-shadow: 0 0 2px rgba(black, .1);
	}

	50% {
		opacity: 1;
		transform: scale(2);
		box-shadow: 0 0 10px rgba(black, .1);
	}

	100% {
		opacity: .0;
		transform: scale(.15);
		box-shadow: 0 0 2px rgba(black, .1);
	}
}


.dataTip {
	font-family: "Apres RE", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 1em;
	line-height: 1.1;
	text-rendering: optimizeLegibility;
	// box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.05);
	vertical-align: middle;
	width: 300px;
	padding-bottom: 5px;
	z-index: 2000;
	// position: absolute;
	// right: 0;
	// bottom: 0;
	// height: 20px;
}

.dataTipHeader {
	border-radius: 5px 5px 0px 0px;
	background: #4e2a84;
	padding: 8px 12px;
	color: #FFF;
	font-size: 1rem;
	text-align:left;
	font-style: normal;
	text-wrap: wrap;
	font-weight: 600;
	line-height: 140%;
	font-family: "Apres RE", "Helvetica Neue", Helvetica, Arial, sans-serif;
	line-height: 1.2;
	text-rendering: optimizeLegibility;
}

.dataTipCount {
	padding: 3px 10px 3px 10px;
	display: flex;
	justify-content: space-between;

}

.dataTipCount {
	span {

		color: #16161D;
		font-size: 1rem;
		font-style: normal;
		font-weight: 400;
		line-height: 140%;
		/* 18.9px */
	}

	.count {
		color: #16161D;
		font-size: 1rem;
		font-style: normal;
		font-weight: 500;
		line-height: 140%;
		/* 18.9px */
	}
}

.mat-mdc-tab-body-content {
	overflow: inherit !important
}

@media only screen and (max-width: 600px) {
	#map-container {
		overflow: auto;
	}
	
	.dataTip {
	display:none !important;
	}

}

@media only screen and (max-width: 900px) {
	.gContainerMain {
		padding-left: 1rem !important;
		padding-right: 1rem !important;
	}

	.gContainerMain2 {
		padding-left: 1rem !important;
		padding-right: 1rem !important;
	}

	.gContainerMain3 {
		padding-left: 1rem !important;
		padding-right: 1rem !important;
	}

	.gContainerHeader {
		padding-left: 1rem !important;
		padding-right: 1rem !important;
	}

	.logo {
		margin: 15px;
	}
	
	.dataTip {
	display:none !important;
	}


}

img,
svg {
	vertical-align: middle;
	margin-bottom: 0px;
}


.legend-label {
    font-family: 'Apres RE' !important;
    font-size: 10px !important;
}

.totalOutlets {
border-color: #8b9ac9;
    border-bottom-style: double;
    border-top: 1px solid #8b9ac9;
    margin-top: 3px;
    margin-bottom: 5px;
}

.mat-mdc-option .mdc-list-item__primary-text {
font-family: 'Apres RE' !important;
font-family: 12px !important;
}
.mdc-list-item--selected .mdc-list-item__primary-text, .mdc-list-item--activated .mdc-list-item__primary-text {
    color: #5e96b3 !important;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after, .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
    color: #5e96b3 !important;
}

.mat-mdc-select-value {
font-family: 'Apres RE' !important;
}